import {objArrMapObj} from "@/untils/otherEvent"
import {getAuctionHeartPriceList, getEditedNewAuctionData} from "@/views/cmsPage/productManage/auctionAboutList/_apis"

export function range(start, end, step) {
    const result = [];
    for (let i = start; i < end; i += step) {
        result.push({
            value: i === 0 ? '00' : `${i}`,
            label: i === 0 ? '00分' : `${i}分`
        })
    }
    return result;
}


export const timeList = [
    {
        value: '20',
        label: '20时',
        children: range(0, 60, 10)
    },
    {
        value: '21',
        label: '21时',
        children: range(0, 60, 10)
    },
]

export const submitSourceType = {
    4: '裸币',
    6: '开放盒子',
    8: '封闭盒'
}

/** 库存来自哪里 */
export const COPY_STORE_SOURCE_TYPE_LIST = [
    {name: '流拍', value: 4},
    {name: '未付款', value: 6},
    {name: '线下交易', value: 8},
]

/** 库存来自哪里对应类 */
export const COPY_STORE_SOURCE_ClASS_TYPE = {
    4: 'color-purple',
    6: 'color-hot-pink',
    8: 'color-tomato'
}

export const copyStoreSourceTypeListMapText = (value) => {
    return objArrMapObj('value', 'name', COPY_STORE_SOURCE_TYPE_LIST)[value]
}


/** 覆盖修改的拍品数据 */
export const overwriteModifiedAuctionData = async (modifiedId, listData, isDelete) => {
    const res = await getEditedNewAuctionData(modifiedId)
    if (res.status !== '200') return
    const { records } = res.data;
    if (records && records.length) {
        records.forEach(el => {
            el.showDoubleInput = false
        })
    }
    let newData = JSON.parse(JSON.stringify(listData))
    if (isDelete) {
        const foundIndex = newData.findIndex(item => item.id === modifiedId)
        newData.splice(foundIndex, 1)
    } else {
        newData.forEach(item => {
            if (item.id === modifiedId) {
                Object.assign(item, records[0])
            }
        })
    }
    return newData
}

/** 获取心理价加价幅度 */
export const getAuctionHeartPriceListEvent = async () => {
    const res = await getAuctionHeartPriceList()
    if (res.status !== '200') return
    const priceKeyList = Object.keys(res.data)
    const priceList =  priceKeyList.map((el) => {
        return {
            name: el,
            value: res.data[el]
        }
    })
    return {
        priceList,
        dataList: res.data
    }
}

/** 瑕疵选择列表 */
export const REMARK_TAG_LIST = [
    { name: '有磨', value: 1,  type: 'text'},
    { name: '轻微有磨', value: 2,  type: 'text'},
    { name: '声音闷(哑)', value: 3,  type: 'text'},
    { name: '轻微不平', value: 4,  type: 'text'},
    { name: '轻微清理', value: 5,  type: 'text'},
    { name: '不平', value: 7,  type: 'text'},
    { name: '有清洗', value: 8,  type: 'text'},
    { name: '铸缺/孔', value: 9,  type: 'text'},
]