<template>
  <a-modal
      v-model="showPopup"
      title="修改评级编号"
      :width="800"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <div class="popup-content">
      <a-form-model
          ref="ruleForm"
          :model="params"
          v-bind="layout"
      >
        <a-form-item label="平台定义瑕疵">
          <div class="w-100-w flex-start-center flex-wrap mt-10">
            <a-tag
                v-for="(item) in remarkTagList"
                :key="item.value"
                :color="currentRemarkTagText.includes(item.name) ? 'red' : ''"
                @click="handleChangeTag(item)"
            >{{ item.name }}</a-tag>
          </div>
        </a-form-item>
        <a-form-item label="用户自定义瑕疵">
          <a-input
              allowClear
              v-model="params.customRemark"
              class="w-600"
              placeholder='用户自定义瑕疵'
          ></a-input>
        </a-form-item>
        <a-form-item label="平台点评">
          <a-input
              allowClear
              v-model="params.platformRemark"
              class="w-600"
              placeholder='平台点评'
          ></a-input>
        </a-form-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import {postAuctionRemark} from "@/views/cmsPage/productManage/auctionAboutList/_apis"
import {REMARK_TAG_LIST} from "@/views/cmsPage/productManage/auctionAboutList/_services"

export default {
  props: ['rateType'],
  data() {
    return {
      showPopup: false,
      remarkTagList: REMARK_TAG_LIST,
      currentRemarkTagText: [],
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      params: {
        id: undefined,
        coinStateRemark: undefined,
        customRemark: undefined,
        platformRemark: undefined,
      },
    }
  },
  methods: {
    /** 展示弹窗 */
    show(data) {
      this.params = data
      this.currentRemarkTagText = data.coinStateRemark ? data.coinStateRemark.split(',') : []
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        id: undefined,
        coinStateRemark: undefined,
        customRemark: undefined,
        platformRemark: undefined,
      }
      this.currentRemarkTagText = []
      this.showPopup = false
    },
    /** 确定 */
    async handleConfirm() {
      const tempData = {
        coinStateRemark: this.currentRemarkTagText.length ? this.currentRemarkTagText.join(',') : null,
        customRemark: this.params.customRemark,
        platformRemark: this.params.platformRemark,
      }
      this.$loading.show()
      const res = await postAuctionRemark({
        id: this.params.id,
        productRemarkVo: tempData
      })
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('编辑成功')
      this.$emit('editorSuccess', {
        id: this.params.id,
        productRemarkVo: tempData
      })
      this.handleCancel()
    },
    /** 改变tag */
    handleChangeTag(item) {
      let index = this.currentRemarkTagText.indexOf(item.name);
      if (index !== -1) {
        this.currentRemarkTagText.splice(index, 1);
      } else {
        this.currentRemarkTagText.push(item.name);
      }
    }
  }
}
</script>