var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 800, title: "视频", footer: null },
      on: { cancel: _vm.close },
      model: {
        value: _vm.showModel,
        callback: function($$v) {
          _vm.showModel = $$v
        },
        expression: "showModel"
      }
    },
    [
      _vm.videoInfo.productExtendInfo
        ? _c("video", {
            staticStyle: { "object-fit": "fill" },
            attrs: {
              src: _vm.videoInfo.productExtendInfo.videoUrl,
              poster: _vm.videoInfo.productExtendInfo.videoCoverUrl,
              preload: "none",
              width: "100%",
              controls: "controls"
            }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }