<template>
  <a-modal
      v-model="showModel"
      :width='800'
      title="视频"
      :footer="null"
      @cancel="close"
  >
    <video
        v-if="videoInfo.productExtendInfo"
        :src="videoInfo.productExtendInfo.videoUrl"
        :poster="videoInfo.productExtendInfo.videoCoverUrl"
        preload="none"
        width="100%"
        style="object-fit: fill;"
        controls="controls"
    ></video>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      showModel: false,
      videoInfo: ''
    };
  },
  methods: {
    /**开启*/
    show(data) {
      this.videoInfo = data
      this.showModel = true;
    },
    /** 关闭 */
    close() {
      this.videoInfo = ''
      this.showModel = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>