var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pages-draggable" },
    [
      _c(
        "draggable",
        {
          attrs: { filter: ".forbid", animation: "300", move: _vm.onMove },
          on: { update: _vm.handleDragEnd },
          model: {
            value: _vm.fileList,
            callback: function($$v) {
              _vm.fileList = $$v
            },
            expression: "fileList"
          }
        },
        [
          _vm.fileList && _vm.fileList.length
            ? _c(
                "transition-group",
                _vm._l(_vm.fileList, function(item, index) {
                  return _c("div", { key: item.uid, staticClass: "img-item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "deleted",
                        on: {
                          click: function($event) {
                            return _vm.handleDeleteImg(item)
                          }
                        }
                      },
                      [
                        _c("a-icon", {
                          attrs: { type: "close", size: "20", color: "#fff" }
                        })
                      ],
                      1
                    ),
                    _c("img", {
                      attrs: { src: item.url, alt: "" },
                      on: {
                        click: function($event) {
                          return _vm.handleShowBigImg(_vm.fileList, index)
                        }
                      }
                    }),
                    _vm.isShowSwitchBtn || _vm.isShowCroppingBtn
                      ? _c(
                          "div",
                          { staticStyle: { "margin-top": "8px" } },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleUseYuanImg(item, index)
                                  }
                                }
                              },
                              [_vm._v("使用原图")]
                            ),
                            _c(
                              "a-tooltip",
                              { attrs: { placement: "top" } },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("span", [_vm._v("重新裁剪")])
                                ]),
                                _c("a-button", {
                                  staticClass: "ml-3",
                                  attrs: { size: "small", icon: "switcher" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleUseCroppingImg(
                                        item,
                                        index
                                      )
                                    }
                                  }
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                }),
                0
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "a-upload",
        {
          key: _vm.imgKey,
          attrs: {
            action: _vm.ip + "/file/upload",
            "list-type": "picture-card",
            data: _vm.postParams,
            "file-list": _vm.scoreList,
            showUploadList: false
          },
          on: { change: _vm.handleChangeUploadImg }
        },
        [
          this.scoreList.length < 6
            ? _c(
                "div",
                [
                  _c("a-icon", { attrs: { type: "plus" } }),
                  _c("div", { staticClass: "ant-upload-text" }, [
                    _vm._v("上传图片")
                  ])
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }